<template>
  <div class="card card-custom gutter-b">
    <div class="col-md-12">
      <div class="card-body">
        <div class="row">
          <div class="col-xl-12">
            <div class="card card-custom gutter-b card-stretch">
              <!--begin::Body-->
              <div class="card-body">
                <div class="justify-content-between d-flex">
                  <div class="d-flex">
                    <b-form class="mr-5">
                      <b-form-group
                        id="input-group-3"
                        label=""
                        label-for="input-3"
                      >
                        <b-form-select
                          id="input-3"
                          v-model="form.pilihan"
                          :options="pilihans"
                          @change="onSelectJenis"
                          style="background-color: #dae1ec"
                        >
                          <template #first>
                            <b-form-select-option :value="null" disabled
                              >Pilih Perundang-undangan:</b-form-select-option
                            >
                            <b-form-select-option :value="'0'"
                              >Semua</b-form-select-option
                            >
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </b-form>
                    <b-form class="mr-5">
                      <b-form-group
                        id="input-group-3"
                        label=""
                        label-for="input-3"
                      >
                        <b-form-select
                          id="input-3"
                          v-model="form.tahun"
                          style="background-color: #dae1ec"
                          @change="onSelectTahun"
                          :options="tahuns"
                        >
                          <template #first>
                            <b-form-select-option :value="null" disabled
                              >Tahun</b-form-select-option
                            >
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </b-form>
                    <b-form class="mr-5">
                      <b-form-group
                        id="input-group-3"
                        label=""
                        label-for="input-3"
                      >
                        <b-form-select
                          id="input-3"
                          v-model="form.keterangan"
                          style="background-color: #dae1ec"
                          :options="keterangans"
                          @change="onSelectKeterangan"
                        >
                          <template #first>
                            <b-form-select-option :value="null" disabled
                              >Keterangan</b-form-select-option
                            >
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </b-form>
                  </div>
                  <div class="d-flex mb-5">
                    <b-form class="mr-5">
                      <b-dropdown split text="Cetak" variant="secondary">
                        <b-dropdown-item href="#" @click="exportToExcel"
                          >Excel</b-dropdown-item
                        >
                        <b-dropdown-item href="#" @click="exportToPDF"
                          >PDF</b-dropdown-item
                        >
                      </b-dropdown>
                    </b-form>

                    <b-form>
                      <button
                        type="button"
                        class="hidden-button"
                        @click="refreshData"
                        id="refreshButton"
                        >
                        <i class="fas fa-sync-alt"></i>
                      </button>
                      <b-button
                        :disabled="
                          this.form.pilihan === null || this.form.pilihan == '0' ? !isDisabled : isDisabled
                        "
                        style="background: #0A65FF; border-radius: 4px; color: #ffffff; font-weight: 600"
                        @click="tambahData"
                        :class="
                          hasPermission('user/add') ? '' : 'hidden-button'
                        "
                      >
                        <i class="fa fa-plus-circle" style="color: #ffffff;"></i
                        >Tambah Data
                      </b-button>
                    </b-form>
                  </div>
                </div>
                <b-alert
                  show
                  variant="light"
                  style="color: #464E5F"
                  v-if="hasPermission('system')"
                  ><i class="fa fa-info-circle mr-2" style="color: #0A65FF"></i
                  >Sebelum menambah data, pilih perundang-undangan terlebih
                  dahulu.</b-alert
                >
                <!--begin::Table-->
                <DxDataGrid
                  :ref="dataGridRefKey"
                  :data-source="dataSource"
                  @selection-changed="selectionChanged"
                  key-expr="id"
                  :show-borders="true"
                  :word-wrap-enabled="true"
                  :column-auto-width="true"
                  :remote-operations="true"
                  :column-hiding-enabled="true"
                >
                  <DxScrolling
                    :use-native="true"
                    :scroll-by-content="true"
                    :scroll-by-thumb="true"
                    show-scrollbar="onHover"
                  />
                  <DxSelection mode="single" />
                  <DxFilterRow :visible="true" />
                  <DxColumn
                    data-field="id"
                    sort-order="desc"
                    :visible="false"
                  />
                  <DxColumn
                    data-field="tahun"
                    caption="Tahun"
                    :allow-filtering="false"
                  />
                  <DxColumn
                    data-field="nama"
                    caption="Judul"
                    :filter-operations="['contains']"
                  />
                  <DxColumn
                    data-field="keterangan"
                    caption="Keterangan"
                    :allow-filtering="false"
                  />
                  <DxColumn
                    data-field="direktorat.name"
                    caption="Pemrakarsa"
                    :allow-filtering="false"
                  />
                  <DxColumn
                    data-field="target"
                    caption="Target"
                    :allow-filtering="false"
                    :customize-text="inCaseEmptyDateDx"
                  />
                  <!--                  <DxColumn
                    data-field="progress[0].judul"
                    caption="Progress"
                    :allow-filtering="false"
                  />-->
                  <DxMasterDetail
                    :enabled="true"
                    template="masterDetailProdukHukum"
                  />
                  <template #masterDetailProdukHukum="{ data: produkHukum }">
                    <ProdukHukumProgress
                      :template-data="produkHukum"
                      @add-progress="refreshData"
                      @delete-progress="refreshData"
                    />
                  </template>
                  <DxColumn type="buttons" caption="Aksi">
                    <DxButton
                      icon="fa fa-eye"
                      hint="Lihat"
                      :onClick="onClickView"
                      :visible="hasPermission('user/view')"
                    />
                    <DxButton
                      icon="fa fa-edit"
                      hint="Ubah"
                      :onClick="onClickEdit"
                      :visible="hasPermission('user/update')"
                    />
                    <DxButton
                      icon="fa fa-trash"
                      hint="Hapus"
                      :onClick="onClickDelete"
                      :visible="hasPermission('user/delete')"
                    />
                  </DxColumn>
                  <DxPaging :page-size="10" />
                  <DxPager
                    :visible="true"
                    :show-page-size-selector="showPageSizeSelector"
                    :allowed-page-sizes="pageSizes"
                  />
                </DxDataGrid>
              </div>
            </div>

            <!--   Modal View Perundangan   -->

            <b-modal
              v-model="showModalView"
              hide-footer
              centered
              size="xl"
              id="modal-view-perundangan"
            >
              <template #modal-header="{ close }">
                <h5 class="title-card-custom">Lihat Produk Hukum</h5>
              </template>
              <b-form @submit.stop.prevent="onSubmitEdit">
                <b-form-group
                  id="example-input-group-3"
                  label-for="select-direktorat"
                >
                  <template v-slot:label>
                    Direktorat <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                    id="select-direktorat"
                    v-model="formView.direktorat"
                    :options="direktorats"
                    @change="onSelectDirectorate"
                    disabled
                  >
                    <!--                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >&#45;&#45; Pilih Salah Satu &#45;&#45;</b-form-select-option
                      >
                    </template>-->
                  </b-form-select>

                  <!--                  <b-form-invalid-feedback id="input-3-live-feedback"
                    >Direktorat harus dipilih.
                  </b-form-invalid-feedback>-->
                </b-form-group>

                <b-form-group
                  id="input-group-subdirektorat"
                  label-for="select-subdirektorat"
                >
                  <template v-slot:label>
                    Sub Direktorat <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                    id="subdirektorat"
                    v-model="formView.subdirektorat"
                    :options="subdirectorates"
                    disabled
                  >
                    <!-- This slot appears above the options from 'options' prop -->
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >-- Pilih salah satu --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>

                <b-form-group
                  id="example-input-group-3"
                  label-for="input-tahun"
                  label="Tahun"
                >
                  <b-form-input
                    id="input-tahun"
                    name="input-tahun"
                    placeholder="Tahun"
                    v-model="formView.tahun"
                    disabled
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="code-input-group"
                  label="Judul"
                  label-for="input-judul"
                >
                  <b-form-input
                    id="input-judul"
                    name="input-judul"
                    placeholder="Judul"
                    v-model="formView.nama"
                    disabled
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="example-input-group-3"
                  label-for="select-perundangan"
                  label="Jenis Perundangan"
                >
                  <b-form-select
                    id="select-perundangan"
                    v-model="formView.pilihan"
                    :options="pilihans"
                    disabled
                  >
                  </b-form-select>
                </b-form-group>

                <b-form-group
                  id="tanggal-group"
                  label-for="target"
                  label="Target"
                >
                  <b-form-datepicker
                    id="target"
                    v-model="formView.tanggal"
                    class="mb-2"
                    disabled
                    placeholder="Pilih Target"
                    locale="id"
                  ></b-form-datepicker>
                </b-form-group>

                <b-form-group
                  id="keterangan-input-group-3"
                  label-for="select-keterangan"
                  label="Keterangan"
                >
                  <b-form-input
                    id="select-keterangan"
                    v-model="formView.keterangan"
                    disabled
                  >
                  </b-form-input>
                </b-form-group>
                <hr />
                <div class="d-flex justify-content-center">
                  <button
                    type="reset"
                    class="btn btn-delete mr-7"
                    @click="closeModal()"
                  >
                    Tutup
                  </button>
                </div>
              </b-form>
            </b-modal>

            <!--   Modal Edit Perundangan   -->

            <b-modal
              v-model="showModalEdit"
              hide-footer
              centered
              size="xl"
              id="modal-edit-perundangan"
            >
              <template #modal-header="{ close }">
                <h5 class="title-card-custom">Edit Produk Hukum</h5>
              </template>
              <b-form @submit.stop.prevent="onSubmitEdit">
                <b-form-group
                  id="example-input-group-3"
                  label-for="select-direktorat"
                >
                  <template v-slot:label>
                    Direktorat <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                    id="select-direktorat"
                    v-model="formEdit.direktorat"
                    :options="direktorats"
                    @change="onSelectDirectorate"
                  >
                    <!--                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >&#45;&#45; Pilih Salah Satu &#45;&#45;</b-form-select-option
                      >
                    </template>-->
                  </b-form-select>

                  <!--                  <b-form-invalid-feedback id="input-3-live-feedback"
                    >Direktorat harus dipilih.
                  </b-form-invalid-feedback>-->
                </b-form-group>

                <b-form-group
                  id="input-group-subdirektorat"
                  label-for="select-subdirektorat"
                >
                  <template v-slot:label>
                    Sub Direktorat <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                    id="subdirektorat"
                    v-model="formEdit.subdirektorat"
                    :options="subdirectorates"
                  >
                    <!-- This slot appears above the options from 'options' prop -->
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >-- Pilih salah satu --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>

                <b-form-group
                  id="example-input-group-3"
                  label-for="input-tahun"
                  label="Tahun"
                >
                  <b-form-input
                    id="input-tahun"
                    name="input-tahun"
                    placeholder="Tahun"
                    v-model="formEdit.tahun"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="code-input-group"
                  label="Judul"
                  label-for="input-judul"
                >
                  <b-textarea
                    id="input-judul"
                    name="input-judul"
                    placeholder="Judul"
                    rows="3"
                    max-rows="6"
                    v-model="formEdit.nama"
                  ></b-textarea>
                </b-form-group>

                <b-form-group
                  id="example-input-group-3"
                  label-for="select-perundangan"
                  label="Jenis Perundangan"
                >
                  <b-form-select
                    id="select-perundangan"
                    v-model="formEdit.pilihan"
                    :options="pilihans"
                    disabled
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >-- Pilih Salah Satu --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>

                <b-form-group
                  id="tanggal-group"
                  label-for="target"
                  label="Target"
                >
                  <b-form-datepicker
                    id="target"
                    v-model="formEdit.tanggal"
                    class="mb-2"
                    placeholder="Pilih Target"
                    locale="id"
                  ></b-form-datepicker>
                </b-form-group>

                <b-form-group
                  id="keterangan-input-group-3"
                  label-for="select-keterangan"
                  label="Keterangan"
                >
                  <b-form-select
                    id="select-keterangan"
                    v-model="formEdit.keterangan"
                    :options="itemKeterangan"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >-- Pilih Salah Satu --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <hr />
                <div class="d-flex justify-content-center">
                  <button
                    type="reset"
                    class="btn btn-delete mr-7"
                    @click="resetFormEdit"
                  >
                    Batal
                  </button>
                  <button type="submit" class="btn btn-save">
                    Simpan
                  </button>
                </div>
              </b-form>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxPager,
  DxFilterRow,
  DxButton,
  DxMasterDetail,
  DxSelection,
  DxScrolling
} from "devextreme-vue/data-grid";
import { handleErrors, isNotEmpty } from "@/core/appUtil/util";
import CustomStore from "devextreme/data/custom_store";
import Swal from "sweetalert2";
import ProdukHukumProgress from "@/view/pages/admin/produk-hukum/ProdukHukumProgress";
import { validationMixin } from "vuelidate";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import moment from "moment";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { mapGetters } from "vuex";
import { dxDateEmpty } from "@/core/helpers/functions";

let token = "";
let jenisQ = "0";
let tahunQ = "0";
let keteranganQ = "0";

var times = 0

const dataGridRefKey = "produk-hukum-data-grid";
const store = new CustomStore({
  key: "id",
  load: function(loadOptions) {
    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      // "filter",
      "totalSummary",
      "group",
      "groupSummary"
    ].forEach(function(i) {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
      }
    });
    params = params.slice(0, -1);

    // search by name if exists
    if (jenisQ !== "0") {
      params += "&jenis_perundangan_id=" + jenisQ;
    }

    if (tahunQ !== "0") {
      params += "&tahun=" + tahunQ;
    }

    if (keteranganQ !== "0") {
      params += "&keterangan=" + keteranganQ;
    }

    if (
      Array.isArray(loadOptions["filter"]) &&
      loadOptions["filter"].length > 0 &&
      loadOptions["filter"][0] === "nama"
    ) {
      params += "&nama=" + loadOptions["filter"][2];
    }

    return fetch(process.env.VUE_APP_URL_LOCAL + `/produk-hukum${params}`)
      .then(response => response.json())
      .then(data => {
        if (data.data === null && times < 5) {
          setTimeout(() => {
            if (times <= 5) {
              document.querySelector("#refreshButton").click();
            }

            times += 1
          }, 500);

        } else {
          return {
            data: data.data != null ? data.data : [],
            totalCount: data.totalCount,
            summary: data.summary,
            groupCount: data.groupCount
          };
        }
      })
      .catch(() => {
        throw "Data Loading Error";
      });
  }
});
const swalSuccess = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-save",
    cancelButton: "btn btn-delete"
  },
  buttonsStyling: false
});

const swalDelete = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-delete",
    cancelButton: "btn btn-cancel"
  },
  buttonsStyling: false
});

export default {
  mixins: [validationMixin, dxDateEmpty],
  name: "TabelUndangUndang",
  components: {
    ProdukHukumProgress,
    DxDataGrid,
    DxColumn,
    DxPager,
    DxPaging,
    DxFilterRow,
    DxButton,
    DxMasterDetail,
    DxSelection,
    DxScrolling
  },
  data() {
    return {
      showModalEdit: false,
      showModalView: false,
      dataSource: store,
      dataGridRefKey,
      showPageSizeSelector: true,
      pageSizes: [5, 10, 20],
      form: {
        pilihan: null,
        tahun: null,
        keterangan: null
      },
      formEdit: {
        id: null,
        direktorat: null,
        subdirektorat: null,
        nama: "",
        tahun: "",
        pilihan: null,
        tanggal: "",
        judul: "",
        keterangan: ""
      },
      formView: {
        id: null,
        direktorat: null,
        subdirektorat: null,
        nama: "",
        tahun: "",
        pilihan: null,
        tanggal: "",
        keterangan: "",
        judul: ""
      },
      jenisPerundangan: null,
      tahuns: [
        {
          text: "Semua",
          value: "0"
        },
        // {
        //   text: "2021",
        //   value: "2021"
        // },
        // {
        //   text: "2020",
        //   value: "2020"
        // }
      ],
      itemKeterangan: [
        {
          text: "Proleg",
          value: "Proleg"
        },
        {
          text: "Non Proleg",
          value: "Non Proleg"
        },
        {
          text: "Lainnya",
          value: "Lainnya"
        }
      ],
      keterangans: [
        {
          text: "Proleg",
          value: "Proleg"
        },
        {
          text: "Non Proleg",
          value: "Non Proleg"
        },
        {
          text: "Lainnya",
          value: "Lainnya"
        },
        {
          text: "Semua",
          value: "0"
        }
      ],
      pilihans: [],
      direktorats: [],
      subdirectorates: [],
      isDisabled: false
    };
  },
  created() {
    this.onSelectDirectorate();
  },
  mounted() {
    // Set list tahuns
    // for (let i = new Date().getFullYear(); i >= 2020; i--) {
    //   this.tahuns.push({
    //     text: i,
    //     value: i
    //   });
    // }

    this.setTahunsDropdown()

    setTimeout(() => {
      let el = this.$root.$el.querySelector("#input-3");

      el.value = '0';
      setTimeout(() => {
        el.dispatchEvent(new Event('change'));
      }, 500);
    }, 500);

    this.$store.dispatch("token/getAccessToken").then(access_token => {
      token = access_token;
    })
    .then(() => {
      fetch(
        encodeURI(
          process.env.VUE_APP_URL_LOCAL + `/master/jenis-perundangan?take=100`
        ),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + token
          }
        }
      )
        .then(handleErrors)
        .then(response => response.json())
        .then(items => {
          const detailMenu = items.data;
          let selectedOptions;
          selectedOptions = [];
  
          if (detailMenu) {
            detailMenu.map(i => {
              return selectedOptions.push({
                text: i.name,
                value: i.id
              });
            });
          }
  
          this.pilihans = selectedOptions;
          // this.form.pilihan = jenisQ;
        });
  
      fetch(
        encodeURI(process.env.VUE_APP_URL_LOCAL + `/master/direktorat?take=100`),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + token
          }
        }
      )
        .then(handleErrors)
        .then(response => response.json())
        .then(items => {
          const detailMenu = items.data;
          let filtered = detailMenu.filter(member => member.name !== "N/A");
          let selectedOptions;
          selectedOptions = [];
  
          if (filtered) {
            filtered.map(i => {
              return selectedOptions.push({
                text: i.name,
                value: i.id
              });
            });
          }
  
          this.direktorats = selectedOptions;
        });
    });

  },
  computed: {
    ...mapGetters({
      hasPermission: "token/hasPermission"
    }),
    dataGrid: function() {
      return this.$refs[dataGridRefKey].instance;
    }
  },
  methods: {
    selectionChanged(e) {
      e.component.collapseAll(-1);
      e.component.expandRow(e.currentSelectedRowKeys[0]);
    },
    tambahData() {
      this.$router.push({
        path:`form/${this.jenisPerundangan}`,
        params: {
          jenisId: this.jenisPerundangan
        }
      });
    },
    validateStateEdit(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    refreshData() {
      this.dataGrid.refresh();
    },
    onClickEdit(e) {
      const data = e.row.data;
      this.formEdit.tahun = data.tahun;
      this.formEdit.nama = data.nama;
      this.formEdit.keterangan = data.keterangan;

      let date = new Date(data.target);

      this.formEdit.tanggal = date != "Invalid Date" && date.getFullYear() > 1970 ? data.target : null;
      this.formEdit.pilihan = data.jenis_perundangan_id;
      this.formEdit.direktorat = data.direktorat_id;
      this.formEdit.subdirektorat = data.subdirektorat_id;
      this.formEdit.id = data.id;
      this.$root.$emit("bv::show::modal", "modal-edit-perundangan", e.row.data);
    },
    onClickView(e) {
      const data = e.row.data;
      this.formView.tahun = data.tahun;
      this.formView.nama = data.nama;
      this.formView.keterangan = data.keterangan;

      let date = new Date(data.target);
      this.formView.tanggal = date != "Invalid Date" && date.getFullYear() > 1970 ? data.target : null;
      this.formView.pilihan = data.jenis_perundangan_id;
      this.formView.direktorat = data.direktorat_id;
      this.formView.subdirektorat = data.subdirektorat_id;
      this.formView.id = data.id;
      this.$root.$emit("bv::show::modal", "modal-view-perundangan", e.row.data);
    },
    onSelectDirectorate() {
      fetch(
        encodeURI(
          process.env.VUE_APP_URL_LOCAL +
            `/master/subdirektorat?direktorat_id=${this.formEdit.direktorat}`
        ),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + token
          }
        }
      )
        .then(handleErrors)
        .then(response => response.json())
        .then(items => {
          const detailMenu = items.data;
          let selectedOptions;
          selectedOptions = [];
          detailMenu.map(i => {
            return selectedOptions.push({
              text: i.name,
              value: i.id
            });
          });
          this.subdirectorates = selectedOptions;
        });
    },
    exportToExcel() {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Produk Hukum");

      exportDataGrid({
        component: this.dataGrid,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "Produk Hukum.xlsx"
          );
        });
      });
    },
    exportToPDF() {
      const doc = new jsPDF({ orientation: "l" });
      exportDataGridToPdf({
        jsPDFDocument: doc,
        component: this.dataGrid
      }).then(() => {
        doc.save("Produk Hukum.pdf");
      });
    },
    onSubmitEdit() {
      let id = this.formEdit.id;
      let data = {
        direktorat_id: this.formEdit.direktorat,
        subdirektorat_id: this.formEdit.subdirektorat,
        jenis_perundangan_id: this.formEdit.pilihan,
        tahun: parseInt(this.formEdit.tahun),
        nama: this.formEdit.nama,
        keterangan: this.formEdit.keterangan,
        target: moment.utc(this.formEdit.tanggal, "YYYY-MM-DD").format()
      };
      swalSuccess.fire({
        title: "Kirim Berkas",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(
            encodeURI(process.env.VUE_APP_URL_LOCAL + `/produk-hukum/${id}`),
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token
              },
              body: JSON.stringify(data)
            }
          )
            .then(handleErrors)
            .then(response => response.json())
            .then(data => {
              Swal.fire({
                position: "center",
                icon: data.status,
                title: "Data berhasil diubah",
                showConfirmButton: false,
                timer: 1500
              });
              this.resetFormEdit();
              this.refreshData();
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Gagal mengubah data",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    resetFormEdit() {
      this.formEdit = {
        direktorat: null,
        pilihan: null,
        nama: "",
        tahun: "",
        tanggal: "",
        subdirektorat: null
      };

      this.$nextTick(() => {
        this.$bvModal.hide("modal-edit-perundangan");
      });
    },
    closeModal() {
      this.$nextTick(() => {
        this.$bvModal.hide("modal-view-perundangan");
      });
    },
    onClickDelete(e) {
      let id = e.row.data.id;
      swalDelete.fire({
        text: "Yakin ingin hapus data?",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        confirmButtonText: "Hapus",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(
            encodeURI(process.env.VUE_APP_URL_LOCAL + `/produk-hukum/${id}`),
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token
              }
            }
          )
            .then(handleErrors)
            .then(response => response.json())
            .then(data => {
              console.log(data);
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil dihapus",
                showConfirmButton: false,
                timer: 1500
              });
              this.refreshData();
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    onSelectTahun() {
      tahunQ = this.form.tahun;
      this.refreshData();
    },
    onSelectJenis(e) {
      this.jenisPerundangan = e;
      jenisQ = this.form.pilihan;
      this.refreshData();
    },
    onSelectKeterangan() {
      keteranganQ = this.form.keterangan;
      this.refreshData();
    },
    setTahunsDropdown() {
      fetch(
        encodeURI(process.env.VUE_APP_URL_LOCAL + `/produk-hukum/years`), {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + token
          }
        }
      )
        .then(handleErrors)
        .then(response => response.json())
        .then(items => {
          const years = items.data;

          if (years) {
            years.sort((n1, n2) => n1 - n2)

            years.map(i => {
              return this.tahuns.push({
                text: i,
                value: i
              });
            });
          }
        });
    }
  }
}
</script>

<style scoped>
.hidden-button {
  display: none;
}
</style>
